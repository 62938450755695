<template>
    <section class="w-full">
        <div class="w-full flex flex-col justify-center items-center bg-brown-100 border-b border-brown-200">
        <div class="select-arrows md:w-4/12 text-4xl">
            <select
                v-model="activeCitySlug"
                name="timetableLocation"
                id=""
                class="
                    font-title
                    text-2xl
                    front-bold
                    border-l
                    border-brown-200
                    border-r
                    bg-brown-100
                "
            >
                <option :value=null>All</option>
                <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.slug"
                >
                {{ city.name }}
                </option>
            </select>
        </div>
    </div>
    <div
        v-if="selected_city"
        class="w-full flex flex-row justify-center items-center  bg-brown-100 pt-5"
    >
        <div class="w-full md:w-4/12 flex flex-row justify-center items-center ">
            <a
                class="py-6 rounded-t-lg text-center uppercase font-title tracking-[3.5px] w-6/12"
                :class="tabActive('clubs') ? 'bg-white' : ''"
                :href="`/cities/${selected_city.slug}/clubs`"
            >
                Clubs
            </a>
            <a
                class="py-6 rounded-t-lg text-center uppercase font-title tracking-[3.5px] w-6/12"
                :class="tabActive('articles') ? 'bg-white' : ''"
                :href="`/cities/${selected_city.slug}/articles`"
            >
                Articles
            </a>
        </div>
    </div>

    <div v-if="show_rating_filters" class="w-full max-w-[960px] m-auto grid grid-cols-12 mt-5 gap-5 p-5 md:p-0">
        <div class="col-span-12 flex flex-col">
            <label class="label">Concept</label>
            <div class="select-arrows w-full text-base">
                <select
                    v-model="activeConcept"
                    name="sector"
                    id=""
                    class="
                        font-title
                        front-bold
                        bg-brown-100
                        rounded-lg
                        p-2.5
                    "
                >
                    <option :value=null>All</option>
                    <option
                        v-for="concept in concepts"
                        :key="concept.id"
                        :value="concept.slug"
                    >
                    {{ concept.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="col-span-12 md:col-span-8 relative">
            <span class="label">Rating</span>
            <div class="overflow-x-scroll">
                <div class="bg-brown-100 min-w-[500px] w-full flex flex-row justify-around rounded-lg">
                    <span class="border-r border-brown-200 flex-grow p-1">
                        <button
                            class="w-full rounded-lg p-2.5 text-center uppercase font-title"
                            :class="ratingEmpty() ? 'bg-orange-500' : ''"
                            @click="updateRatings('all')"
                        >
                            all
                        </button>
                    </span>
                    <span class="border-r border-brown-200 flex-grow p-1">
                        <button
                            class="w-full h-full flex justify-center items-center rounded-lg p-2.5 flex flex-row justify-center"
                            :class="ratingActive('five') ? 'bg-orange-500' : ''"
                            @click="updateRatings('five')"
                        >
                            <img src="/svg/five-black.svg" class="fill-black" alt="5 star rating" style="width: 295px;" />
                        </button>
                    </span>
                    <span class="border-r border-brown-200 flex-grow p-1">
                        <button
                            class="w-full h-full flex justify-center items-center rounded-lg p-2.5 flex flex-row justify-center"
                            :class="ratingActive('four') ? 'bg-orange-500' : ''"
                            @click="updateRatings('four')"
                        >
                            <img src="/svg/four.svg" class="fill-black" alt="4 star rating" style="width: 278px;" />
                        </button>
                    </span>
                    <span class="flex-grow p-1">
                        <button
                            class="w-full h-full flex justify-center items-center rounded-lg p-2.5 text-center"
                            :class="ratingActive('recommended') ? 'bg-orange-500' : ''"
                            @click="updateRatings('recommended')"
                        >
                            <img src="/svg/recommended-2.svg" alt="recommended" style="width: 200px;" />
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-span-6 md:col-span-2">
            <span class="label">Order</span>
            <div class="bg-brown-100 w-full flex flex-row justify-around rounded-lg overflow-hidden h-[55px]">
                <span class="border-r border-brown-200 flex-grow p-1">
                    <button
                        @click.prevent="changeOrder('asc')"
                        class="w-full h-full flex justify-center items-center rounded-lg p-2.5 flex flex-row justify-center"
                        :class="order === 'asc' ? 'bg-orange-500' : ''"
                    >
                        <img src="/svg/az.svg" alt="grid view" />
                    </button>
                </span>
                <span class="flex-grow p-1">
                    <button
                        @click.prevent="changeOrder('desc')"
                        class="w-full h-full flex justify-center items-center rounded-lg p-2.5 flex flex-row justify-center"
                        :class="order === 'desc' ? 'bg-orange-500' : ''"
                    >
                        <img src="/svg/za.svg" alt="list view" />
                    </button>
                </span>
            </div>
        </div>

        <div class="col-span-6 md:col-span-2">
            <span class="label">View</span>
            <div class="bg-brown-100 w-full flex flex-row justify-around rounded-lg overflow-hidden h-[55px]">
                <span class="border-r border-brown-200 flex-grow p-1">
                    <button
                        @click.prevent="changeView('grid')"
                        class="w-full h-full flex justify-center items-center rounded-lg p-2.5 flex flex-row justify-center"
                        :class="view === 'grid' ? 'bg-orange-500' : ''"
                    >
                        <img src="/svg/grid.svg" alt="grid view" />
                    </button>
                </span>
                <span class="flex-grow p-1">
                    <button
                        @click.prevent="changeView('list')"
                        class="w-full h-full flex justify-center items-center rounded-lg p-2.5 flex flex-row justify-center"
                        :class="view === 'list' ? 'bg-orange-500' : ''"
                    >
                        <img src="/svg/list.svg" alt="list view" />
                    </button>
                </span>
            </div>
        </div>

        <div class="col-span-12 md:col-span-9">
            <label class="label">Search</label>
            <div class="text-base search-club overflow-hidden">
                <input
                    type="text"
                    class="w-full font-title front-bold bg-brown-100 rounded-lg p-2.5 h-[55px]"
                    name=""
                    :value="searchClubKeyword"
                    @input="handleInputClub($event)"
                    @keypress="handleInputClub($event)"
                />

                <button
                    v-if="searchClubKeyword"
                    @click="reset()"
                    class="
                        absolute right-0
                        h-[55px]
                        p-2.5
                        uppercase font-title"
                    >
                    X
                </button>
            </div>
        </div>
        <div class="col-span-12 md:col-span-3">
            <span class="label hidden md:block">&nbsp;</span>
            <div class="bg-brown-100 w-full flex flex-row justify-around rounded-lg overflow-hidden h-[55px]">
                <span class="border-r border-brown-200 flex-grow">
                    <button
                        :disabled="!searchClubKeyword"
                        @click.prevent="searchKeyword()"
                        class="
                            w-full
                            h-full
                            flex justify-center items-center
                            rounded-lg
                            p-2.5
                            bg-orange-500 disabled:opacity-75
                            uppercase font-title"
                        >
                            Search
                    </button>
                </span>
            </div>
        </div>
    </div>

    </section>
</template>

<script>
export default {
    props: {
        selected_city: {
            type: Object,
            required: false,
            default: null
        },
        ratings: {
            type: Array
        },
        order: {
            type: String
        },
        view: {
            type: String,
        },
        keyword: {
            type: String,
        },
        show_rating_filters: {
            type: Boolean,
            required: false,
            default: true
        },
        concept: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            activePage: "clubs",
            activeCitySlug: this.selected_city ? this.selected_city.slug : null,
            activeConceptSlug: null,
            cities: [],
            activeConcept: this.concept ? this.concept : null,
            concepts: [],
            searchClubKeyword: this.keyword ? this.keyword : null,
            // order: 'asc',
            // view: 'grid',
        }
    },
    methods: {
        async getCities () {
            fetch("/api/cities", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => response.json())
            .then((response) => {
                this.cities = response.data;
            })
            .catch((error) => {
                this.error = 'Error getting posts.';
            });
        },
        async getConcepts () {
            fetch("/api/clubs/concepts", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => response.json())
            .then((response) => {
                for (const [key, concept] of Object.entries(response.data)) {
                    if (concept) {
                        this.concepts.push(concept)
                    }
                }
            })
            .catch((error) => {
                this.error = 'Error getting posts.';
            });
        },
        changeOrder (order) {
            this.$emit('update:order', order);
        },
        changeView (view) {
            this.$emit('update:view', view);
        },
        searchClub (keyword) {
            this.$emit('update:keyword', keyword);
        },
        tabActive (tab) {
            return location.href.includes(tab);
        },
        ratingActive (rating) {
            return this.ratings.includes(rating)
        },
        ratingEmpty(){
            return this.ratings.length === 0;
        },
        updateRatings (rating) {
            const ratingIndex = this.ratings.indexOf(rating);

            if (rating === 'all') {
                this.ratings.splice(0, this.ratings.length);
            } else {
              if (ratingIndex >= 0) {
                  this.ratings.splice(ratingIndex, 1);
              } else {
                  this.ratings.push(rating);
              }
            }
        },
        handleInputClub(event) {
            // enter key was pressed!
            if (event.keyCode === 13) {
                this.searchKeyword()
            }
            this.searchClubKeyword = event.target.value;
        },
        searchKeyword() {
            this.searchClub(this.searchClubKeyword);
        },
        reset() {
            this.searchClubKeyword = null;
            window.location.reload();
        }
    },
    mounted() {
        this.getCities();

        if (this.show_rating_filters) {
            this.getConcepts();
        }
    },
    watch: {
        activeCitySlug(new_val, old_val) {
            if (new_val !== old_val) {
                // handle if user selects "All" option.
                let route = `/${this.activeCitySlug}`;
                if (!new_val) { route = '';  }
                window.location = `/cities${route}`;
            }
        },
        activeConcept() {
            this.$emit('update:concept', this.activeConcept);
        }
    }
}
</script>
