import Vue from 'vue';
import EnsoCarousel from 'enso-carousel';
import MainMenu from './enso/MainMenu.js';

import Chart from './components/charts/Chart.vue'
import IconFiveStars from "./components/icons/IconFiveStars"
import IconFourStars from "./components/icons/IconFourStars"
import IconThreeStars from "./components/icons/IconThreeStars"
import IconTwoStars from "./components/icons/IconTwoStars"
import IconOneStar from "./components/icons/IconOneStar"
import IconRecommended from "./components/icons/IconRecommended"
import IconRated from "./components/icons/IconRated"
import Notification from './components/global/Notification.vue';
import Carousel from './components/carousel/Carousel.vue';
import Lightbox from './components/global/Lightbox.vue'
import ArticlesIndex from './components/articles/ArticlesIndex.vue';
import TopCities from  './components/cities/TopCities.vue'
import CitiesIndex from './components/cities/CitiesIndex.vue';
import StudiosIndex from './components/studios/StudiosIndex.vue';

import RankingsIndex from './components/enterprise/RankingsIndex.vue';
import ReportsIndex from './components/enterprise/ReportsIndex.vue';
import DashboardIndex from './components/enterprise/DashboardIndex.vue';
import NewsletterDialog from './components/dialog/Newsletter.vue';

import CourseList from './components/courses/CourseList.vue';
import CourseIndex from './components/courses/CourseIndex.vue';

import '@fortawesome/fontawesome-free/css/all.min.css'; // For CSS
import '@fortawesome/fontawesome-free/js/all.js'; // For JS (optional)

new Vue({
  el: '#app',
  components: {
    EnsoCarousel,
    MainMenu,
    Chart,
    IconFiveStars,
    IconFourStars,
    IconThreeStars,
    IconTwoStars,
    IconOneStar,
    IconRecommended,
    IconRated,
    Notification,
    Carousel,
    Lightbox,
    ArticlesIndex,
    TopCities,
    CitiesIndex,
    StudiosIndex,
    RankingsIndex,
    ReportsIndex,
    NewsletterDialog,
    DashboardIndex,
    CourseList,
    CourseIndex,
  },
});
